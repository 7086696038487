export function useBillingAddressHelper($t) {
  let countryLabelParser = {
    US: {
      street: 'BillingAddressModal.AddressLabel',
      city: 'AppPaymentCardModal.BillingAddressCityLabel',
      state: 'AppPaymentCardModal.BillingAddressStateLabel',
      postalCode: 'AppPaymentCardModal.BillingAddressZipCodeLabel',
      country: 'AppPaymentCardModal.BillingAddressCountryLabel',
    },
    AU: {
      street: 'BillingAddressModal.AddressLabel',
      city: 'BillingAddressModal.LocalityLabel',
      state: 'AppPaymentCardModal.BillingAddressStateLabel',
      postalCode: 'BillingAddressModal.PostalCodeLabel',
      country: 'AppPaymentCardModal.BillingAddressCountryLabel',
    },
    NZ: {
      street: 'BillingAddressModal.AddressLabel',
      city: 'BillingAddressModal.LocalityLabel',
      //state: 'AppPaymentCardModal.BillingAddressStateLabel',
      postalCode: 'BillingAddressModal.PostalCodeLabel',
      country: 'AppPaymentCardModal.BillingAddressCountryLabel',
    },
    CA: {
      street: 'BillingAddressModal.AddressLabel',
      city: 'BillingAddressModal.LocalityLabel',
      state: 'BillingAddressModal.ProvinceLabel',
      postalCode: 'BillingAddressModal.PostalCodeLabel',
      country: 'AppPaymentCardModal.BillingAddressCountryLabel',
    },
    GB: {
      street: 'BillingAddressModal.AddressLabel',
      city: 'BillingAddressModal.TownCityLabel',
      state: 'AppPaymentCardModal.BillingAddressStateLabel',
      postalCode: 'BillingAddressModal.PostalCodeLabel',
      country: 'AppPaymentCardModal.BillingAddressCountryLabel',
    },
    EU: {
      street: 'BillingAddressModal.AddressLabel',
      city: 'BillingAddressModal.LocalityLabel',
      state: 'BillingAddressModal.StateProvinceMunicipalityLabel',
      postalCode: 'BillingAddressModal.PostcodeLabel',
      country: 'AppPaymentCardModal.BillingAddressCountryLabel',
    },
  }

  const getLabelBasedOnCountry = (country, key) => {
    let pivot = countryLabelParser[country]
    if (!pivot) {
      pivot = countryLabelParser.EU
    }
    return pivot[key]
  }

  const billingAddressDetails = [
    {
      label: $t('AppPaymentCardModal.BillingAddressStreetNameLabel').value,
      value: 'street',
    },
    {
      label: $t('AppPaymentCardModal.BillingAddressCityLabel').value,
      value: 'city',
    },
    {
      label: $t('AppPaymentCardModal.BillingAddressStateLabel').value,
      value: 'state',
    },
    {
      label: $t('AppPaymentCardModal.BillingAddressZipCodeLabel').value,
      value: 'postalCode',
    },
    {
      label: $t('AppPaymentCardModal.BillingAddressCountryLabel').value,
      value: 'country',
      type: 'country',
    },
  ]

  const getUpdatedBillingAddressDetails = (country) => {
    let copiedAddress = Object.assign([], billingAddressDetails)
    let indexToRemove = null

    for (let index in copiedAddress) {
      const pivot = copiedAddress[index]
      let label = getLabelBasedOnCountry(country, pivot.value)
      if (label) {
        pivot.label = $t(label).value
      } else {
        indexToRemove = index
      }
    }
    if (indexToRemove) {
      copiedAddress.splice(+indexToRemove, 1)
    }
    return copiedAddress
  }

  return { getUpdatedBillingAddressDetails }
}

<template>
  <AppModal v-model="model" :title="$t('ComponentCardCVVModal.TitleModal').value">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #right>
          <AppBackButton analytics-name="card-cvv-modal-back" @click="dismiss" />
        </template>
      </AppModalHeader>
    </template>

    <XeCardImageTitle
      :src="require('@galileo/assets/images/illustrations/recolorable/cvv.svg')"
      alt="Card CVV illustration"
      :title="$t('ComponentCardCVVModal.Header').value"
    >
      {{ $t('ComponentCardCVVModal.Description').value }}
    </XeCardImageTitle>

    <template #footer>
      <AppCardFooter class="modal-footer">
        <AppButton analytics-name="card-cvv-modal-ok" @click="model = false">{{
          $t('ComponentCardCVVModal.ButtonGotIt').value
        }}</AppButton>
      </AppCardFooter>
    </template>
  </AppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import XeCardImageTitle from '@galileo/components/XeCardImageTitle/XeCardImageTitle'

import {
  useVModel,
  AppModal,
  AppModalHeader,
  AppButton,
  AppCardFooter,
  AppBackButton,
} from '@oen.web.vue2/ui'

export default {
  name: 'CardCVVModal',
  components: {
    AppModal,
    AppModalHeader,
    AppButton,
    AppCardFooter,
    AppBackButton,
    XeCardImageTitle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    return {
      model,
      $t,
    }
  },
}
</script>

<style scoped></style>

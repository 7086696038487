<template>
  <div>
    <form autocomplete="off" @submit.prevent="submit">
      <AppModal v-model="model" :title="$t('ComponentAddPaymentCardModal.TitleModal').value">
        <template #header="{ dismiss }">
          <AppModalHeader>
            <template #left>
              <AppBackButton
                name="Go Back"
                icon="<"
                analytics-name="add-payment-card-modal-back"
                @click="dismiss"
              />
            </template>

            <h1 v-if="cardName === 'debit'">
              {{ pageDebitHeader }}
            </h1>
            <h1 v-else-if="cardName === 'credit'">
              {{ pageCreditHeader }}
            </h1>

            <template #right>
              <AppBackButton
                analytics-name="add-payment-card-modal-back-right"
                @click="$router.toParentRoute()"
              />
            </template>
          </AppModalHeader>
        </template>
        <div>
          <h2 class="card-details-title">
            {{ pageTitle }}
          </h2>
          <span class="card-details-info">
            {{ $t('AppPaymentCardModal.EnterCardDetailsInfoText').value }}
          </span>
        </div>
        <div v-if="billingAddressDetailsFields && billingAddressDetailsFields.length > 0">
          <!-- Save card Checkbox -->
          <AppCard centered class="checkbox-container">
            <div class="checkbox-row">
              <AppInputCheckbox
                v-model="form.saveCard"
                :disabled="cardHelper.cardLimitReached.value"
              />
              <p>{{ $t('AppPaymentCardModal.SaveCardLabel').value }}</p>
            </div>
          </AppCard>

          <!-- Adyen loading state -->
          <CardFieldsLoader
            v-show="isAdyen"
            class="adyen-loader"
            :is-loading="!adyenData.cardFieldsLoaded"
          />

          <!-- Adyen wrapper -->
          <div
            id="customCard-container"
            v-if="isAdyen"
            :class="{
              'card-fields-loaded': adyenData.cardFieldsLoaded,
            }"
          >
            <AppAlert
              v-if="adyenData && adyenData.validationError && adyenData.isValid"
              class="adyen-validation-error"
            >
              {{ adyenData.validationError }}
            </AppAlert>

            <AppSpinnerBig v-if="adyenData.loading" loading />
          </div>

          <div v-if="!isAdyen">
            <AppInputMask
              v-model="validation.cardNumber.$value"
              class="margin-update"
              analytics-name="add-payment-card-modal-cardnumber"
              :label="$t('ComponentAddPaymentCardModal.LabelCardNumber').value"
              mask="#### #### #### ####"
              placeholder="0000 0000 0000 0000"
              :validation="validation.cardNumber"
              autocomplete="off"
              inputmode="numeric"
              @input="resetMinLength"
              @blur="onCardNumberFocusOut"
              data-cse="encryptedCardNumber"
            >
              <template #rightIcon>
                <Transition name="fade" mode="out-in">
                  <div class="card-images">
                    <img v-if="!showCardsImages" :src="cardIconSrc" alt="card brand" />
                    <img
                      v-if="validation.cardNumber.$anyInvalid"
                      src="@galileo/assets/images/cards/wrong-card.svg"
                      alt="wrong card"
                    />
                    <img
                      alt="card number"
                      v-if="showCardsImages && !validation.cardNumber.$anyInvalid"
                      src="@galileo/assets/images/cards/card-number-icon.svg"
                    />
                  </div>
                </Transition>
              </template>
            </AppInputMask>
            <div v-if="showCardsImages" class="cards-images">
              <img src="/icons/mastercard-black.svg" alt="mastercard" />
              <img src="/icons/visa-black.svg" alt="visa" />
            </div>

            <div class="grid sm:grid-cols-2">
              <div class="col-span-1 sm:pr-2">
                <AppInputMask
                  v-model="validation.expirationDate.$value"
                  analytics-name="add-payment-card-modal-expiry-date"
                  :label="$t('AddPaymentCardModel.LabelExpirationDate').value"
                  :validation="validation.expirationDate"
                  :helper-text="
                    $t('ComponentAddPaymentCardModal.LabelExpirationDateHelperText').value
                  "
                  autocomplete="off"
                  mask="##/##"
                  inputmode="numeric"
                  data-cse="encryptedExpiryDate"
                >
                  <template #rightIcon>
                    <Transition name="fade" mode="out-in">
                      <div v-if="validation.expirationDate.$anyInvalid" class="card-images">
                        <img
                          src="@galileo/assets/images/cards/card-expiry-date-icon.svg"
                          alt="card expiry date"
                        />
                      </div>
                      <div v-else class="card-images">
                        <AppIcon class="checkmark-icon">
                          <IconCheckmark />
                        </AppIcon>
                      </div>
                    </Transition>
                  </template>
                </AppInputMask>
              </div>

              <div class="col-span-1 sm:pl-2">
                <AppInputMask
                  v-model="validation.securityCode.$value"
                  analytics-name="add-payment-card-modal-cvv"
                  :label="$t('AddPaymentCardModal.LabelSecurityCode').value"
                  :validation="validation.securityCode"
                  :helper-text="
                    $t('ComponentAddPaymentCardModal.LabelSecurityCodeHelperText').value
                  "
                  autocomplete="off"
                  mask="###"
                  inputmode="numeric"
                  data-cse="encryptedSecurityCode"
                >
                  <template #rightIcon>
                    <Transition name="fade" mode="out-in">
                      <div v-if="validation.securityCode.$anyInvalid" class="card-images">
                        <img
                          src="@galileo/assets/images/cards/card-security-code-icon.svg"
                          alt="card security code"
                        />
                      </div>
                      <div v-else class="card-images">
                        <AppIcon class="checkmark-icon">
                          <IconCheckmark />
                        </AppIcon>
                      </div>
                    </Transition>
                  </template>
                </AppInputMask>
              </div>
            </div>
          </div>

          <AppInputDropdown
            v-if="showCardType"
            v-model="validation.cardTypeId.$value"
            :validation="validation.cardTypeId"
            :label="$t('CardPayment.CardTypeLabel').value"
            analytics-name="add-payment-card-modal-cardtype"
            :options="cardHelper.cardTypeList.value"
            :placeholder="$t('CardPayment.CardTypePlaceholder').value"
            selected-display-field="name"
            selected-value-field="id"
            @input="onCardTypeChange"
          />

          <AppInputText
            :class="{
              'add-margin': adyenData.cardFieldsLoaded,
            }"
            v-model="validation.cardHolderName.$value"
            data-testid="add-payment-card-cardholdername"
            :validation="validation.cardHolderName"
            :label="$t('AppPaymentCardModal.CardHolderName').value"
            @input="resetCardHolderNameMinWords"
            @blur="onCardHolderNameFocusOut"
          />

          <AppAlert theme="blue" class="card-warning">
            {{ $t('AppPaymentCardModal.CardWillNotBeChargedInfo').value }}
          </AppAlert>

          <BillingAddressInfo
            :is-new-card="isNewCard"
            :title="$t('AppPaymentCardModal.BillingAddressTitle').value"
            :fields="billingAddressDetailsFields"
            :cta-label="$t('AppPaymentCardModal.BillingAddressEditButton').value"
            @onSave="onBillingAddressInfoSaved"
            @updateCheckboxState="handleCheckboxStateChange"
          />
        </div>

        <div v-else-if="!adyenData.cardFieldsLoaded" class="loading-container">
          <AppSpinnerBig loading />
        </div>

        <template #footer>
          <AppCardFooter>
            <AppButton
              :disabled="isContinueDisabled"
              :loading="addCardLoading"
              type="submit"
              analytics-name="add-payment-card-modal-add"
            >
              {{ $t('AppPaymentCardModal.ConfirmTextButton').value }}
            </AppButton>
          </AppCardFooter>
        </template>
      </AppModal>
    </form>
    <!-- CVV Helper modal  -->
    <CardCVVModal v-model="isCVVModalOpen" />
  </div>
</template>

<script>
import { reactive, ref, computed, toRef, watch, onBeforeMount } from '@vue/composition-api'

import { getCorrectedString } from '@galileo/utilities/sanitizers/sanitizeString.js'

import { usePromiseLazy, useValidation } from 'vue-composable'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import { numeric, helpers, minLength } from '@vuelidate/validators'
import { deburr } from 'lodash-es'

import endOfMonth from 'date-fns/endOfMonth'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import addYears from 'date-fns/addYears'
import parse from 'date-fns/parse'
import isValid from 'date-fns/isValid'
import subMonths from 'date-fns/subMonths'
import { useRouter } from '@galileo/composables/useRouter'
import CardCVVModal from '@galileo/components/Views/PaymentMethod/CardCVVModal.vue'
import Address from '@galileo/models/Address/address'
import useCardValidation from '@galileo/composables/useCardValidation/index.js'
import BillingAddressInfo from '@galileo/components/Views/PaymentMethod/BillingAddressInfo'

import { useBillingAddressHelper } from '@galileo/composables/useAddress/useBillingAddressHelper'
import useCardHelper from '@galileo/composables/useCardHelper/index.ts'

import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYMENT_METHOD_TYPES,
} from '@galileo/constants/segmentAnalytics'

import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import CardFieldsLoader from '@galileo/components/Views/PaymentMethod/CardPaymentMethodUtils/CardFieldsLoader'

import {
  useI18nStore,
  useCountriesStore,
  useSendMoneyStore,
  useCardAccountStore,
  useAnalyticsStore,
  useAppStore,
  usePaymentsStore,
  useAuthStore,
} from '@galileo/stores'

import {
  useAlert,
  useVModel,
  AppButton,
  AppCardFooter,
  AppInputText,
  AppInputMask,
  AppModal,
  AppModalHeader,
  AppInputDropdown,
  AppBackButton,
  AppSpinnerBig,
  AppSpinner,
  AppInputCheckbox,
  AppCard,
  AppIcon,
  AppAlert,
} from '@oen.web.vue2/ui'

import {
  PAYMENT_GATEWAY,
  CARD_FULL_TYPE,
  CARD_BRAND_MAPPINGS,
} from '@galileo/constants/cardPayments.const'
import useAdyen from '@galileo/composables/useAdyen'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { IconCheckmark } from '@oen.web.vue2/icons'
import { extractRegexMaxLength } from '@galileo/utilities/regex.utility'
import { truncateString } from '@galileo/utilities/string-utils'

export default {
  name: 'AddPaymentCardModal',
  components: {
    AppCard,
    AppInputCheckbox,
    AppSpinnerBig,
    AppCardFooter,
    AppButton,
    AppInputText,
    AppInputMask,
    AppModal,
    AppModalHeader,
    AppInputDropdown,
    CardCVVModal,
    AppBackButton,
    BillingAddressInfo,
    CardFieldsLoader,
    IconCheckmark,
    AppIcon,
    AppAlert,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isCvv: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const countriesStore = useCountriesStore()
    const analyticsStore = useAnalyticsStore()
    const cardAccount = useCardAccountStore()
    const router = useRouter()
    const paymentsStore = usePaymentsStore()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()
    const authStore = useAuthStore()

    const { init, mountCardFields, adyenData } = useAdyen()

    const paymentGateway = authStore.userProfile.customer.cardPaymentGateway

    const cardHelper = useCardHelper()

    const { validatedCardInformation } = useCardValidation(null)

    const { add: addAlert } = useAlert()

    const country = sendMoneyStore.getCountryTo

    const newCard = paymentsStore.selectedPaymentMethod

    const billingAddressDetails = ref([])

    const { expirationDate, securityCode, cardBrand, cardPspCode, cardType } = newCard

    const showCardType = ref(false)

    const { getUpdatedBillingAddressDetails } = useBillingAddressHelper($t)

    const appellativeWordsRegex = new RegExp(/^\s*(Mr|Mrs|Ms|Miss)\b/i)

    const valueRequired = getValueRequiredValidation()
    const showCardsImages = ref(true)
    const isVisaCard = ref(false)
    const isMastercard = ref(false)
    const pageTitle = ref($t('AppPaymentCardModal.EnterCardDetailsText').value)
    const pageCreditHeader = ref($t('AppPaymentCardModal.AddCreditCardNewTitleText').value)
    const pageDebitHeader = ref($t('AppPaymentCardModal.AddDebitCardTitleText').value)

    const checkboxState = ref(false)
    const isBillingAddressValid = ref(false)

    const sameAsResidentialAddressFlag = ref(false)
    const isNewCard = cardAccount.getIsNewCard()

    const handleCheckboxStateChange = (sameAsResidentialAddress) => {
      if (sameAsResidentialAddress) {
        sameAsResidentialAddressFlag.value = true
        const address = useAuthStore().getUserAddress()

        billingAddress.value = new Address(
          address.line1,
          address.place,
          address.state,
          address.postCode,
          address.countryCode
        )
        isBillingAddressValid.value = true
      }
    }

    const form = reactive({
      cardholderName: '',
      cardNumber: '',
      cardType,
      expirationDate,
      securityCode,
      cardBrand,
      cardPspCode,
      saveCard: true,
      getFirstName() {
        const matchAppellative = appellativeWordsRegex.test(this.cardholderName)
        let split = this.cardholderName.trim().split(' ')
        let firstName = split[0]
        if (matchAppellative) {
          firstName = `${split[0]} ${split[1]}`
        }
        firstName = deburr(firstName)
        return firstName
      },
      getSurname() {
        const matchAppellative = appellativeWordsRegex.test(this.cardholderName)
        let split = this.cardholderName.trim().split(' ')
        split.shift()
        if (matchAppellative) {
          split.shift()
        }
        let lastName = split.join(' ')
        lastName = deburr(lastName)
        return lastName
      },
      cardTypeId: '',
    })

    const newCardName = sendMoneyStore.form.paymentMethod

    const cardName = computed(() => {
      //CreditCard
      if (form.cardType === PAYMENT_METHODS.DEBIT_CARD) {
        return 'debit'
      } else {
        return 'credit'
      }
    })

    const cardIconSrc = computed(() => {
      if (isVisaCard.value) {
        return '/icons/visa-black.svg'
      }
      if (isMastercard.value) {
        return '/icons/mastercard-black.svg'
      }
    })

    //#region Billing Address
    const billingAddress = ref()

    const mapBillingAddressFromSelectedCard = () => {
      billingAddress.value = new Address(
        newCard.billingStreet,
        newCard.billingCity,
        newCard.billingState,
        newCard.billingPostalCode,
        newCard.billingCountry
      )
    }

    const sanitizeAddressFields = (addressString) => {
      if (addressString) {
        return removeDiacritics(translit(addressString))
      }
      return ''
    }

    const sanitizeBillingAddressFields = (address) => {
      if (address.country) {
        address.country = sanitizeAddressFields(address.country)
      }
      if (address.street) {
        address.street = sanitizeAddressFields(address.street)
      }
      if (address.city) {
        address.city = sanitizeAddressFields(address.city)
      }
      if (address.state) {
        address.state = sanitizeAddressFields(address.state)
      }
      if (address.postalCode) {
        address.postalCode = sanitizeAddressFields(address.postalCode)
      }
    }

    const onBillingAddressInfoSaved = ({ address, addressValid }) => {
      isBillingAddressValid.value = addressValid

      if (address.billingAddress2) {
        address.billingAddress = `${address.billingAddress}, ${address.billingAddress2}`
      }

      // Prepare the address before sending to backend
      const maxAddressLengthRegex = cardAccount.cardSettings.cardFields.find(
        (item) => item.name === 'BillToAddressLine1'
      ).regEx
      let maxAddressLength = 60
      if (maxAddressLengthRegex) {
        maxAddressLength = extractRegexMaxLength(maxAddressLengthRegex)
      }

      billingAddress.value = new Address(
        truncateString(getCorrectedString(address.billingAddress), maxAddressLength),
        getCorrectedString(address.billingCity),
        getCorrectedString(address.billingState),
        getCorrectedString(address.billingPostalCode),
        address.billingCountry
      )

      billingAddressDetails.value = getUpdatedBillingAddressDetails(billingAddress.value.country)

      //we update the selectedPaymentMethod with the updated address values
      let cardDetails = { ...form, ...address }

      paymentsStore.setSelectedPaymentMethod(cardDetails)
    }

    //#endregion

    const isCVVModalOpen = ref(false)

    const clickSecurityInfo = () => {
      isCVVModalOpen.value = true
    }

    onBeforeMount(async () => {
      const candidateForUpdate = cardAccount.selectedCardForUpdate

      if (candidateForUpdate) {
        form.cardholderName = `${candidateForUpdate.firstName} ${candidateForUpdate.lastName}`

        const maskedNr = cardHelper.createCardTitle({
          maskedAccountNumber: candidateForUpdate.maskedAccountNumber,
        })
        const cardType = candidateForUpdate.type.split(' ')[0]

        pageDebitHeader.value = $t('AppPaymentCardModal.UpdateDebitCardTitleText').value
        pageCreditHeader.value = $t('AppPaymentCardModal.UpdateCreditCardTitleText').value

        pageTitle.value = $t('AppPaymentCardModal.UpdateCardDetailsText', {
          cardType,
          maskedNr,
        }).value
      }

      await cardHelper.retrieveCardSettings()

      form.saveCard = !cardHelper.cardLimitReached.value

      if (newCard?.cardholderName && newCard.cardholderName != form.cardholderName) {
        form.cardholderName = newCard.cardholderName
      }

      //we trigger number check again when coming back from billing address.
      form.cardNumber = newCard.cardNumber
      paymentsStore.addCard(form)

      if (!newCard.billingAddress || candidateForUpdate) {
        let tempCard = paymentsStore.selectedPaymentMethod

        if (candidateForUpdate) {
          tempCard = candidateForUpdate
        }

        let state
        if (tempCard.billingState) {
          try {
            const states = await countriesStore.getStatesByCountryCode(tempCard.billingCountry)
            if (states) {
              state = states.find((state) => state.name === tempCard.billingState)
            }
          } catch (ex) {
            appStore.logException('Error retriving states', ex)
          }
        }

        newCard.billingStreet = tempCard.billingStreet
        newCard.billingCity = tempCard.billingCity

        //if we need the state isoCode switch the below assignment
        newCard.billingState = tempCard.billingState || state?.value

        newCard.billingPostalCode = tempCard.billingPostalCode
        newCard.billingCountry = tempCard.billingCountry
      }
      mapBillingAddressFromSelectedCard()
      billingAddressDetails.value = getUpdatedBillingAddressDetails(billingAddress.value.country)
      await handleAdyenCheckout()
    })

    let customCard = null

    const isAdyen = ref(false)
    const handleAdyenCheckout = async () => {
      if (paymentGateway === PAYMENT_GATEWAY.ADYEN) {
        isAdyen.value = true
        await init()
        customCard = await mountCardFields('#customCard-container')
      }
    }

    const onCardTypeChange = (typeId) => {
      let selectedCardType = cardHelper.findTypeByProperty('id', typeId)

      if (selectedCardType) {
        form.cardBrand = selectedCardType.code
        form.cardPspCode = selectedCardType.cardPspCode
        form.cardTypeId = selectedCardType.id

        form.cardType =
          selectedCardType.cardType === 'Credit' || selectedCardType.cardType === 'CreditCard'
            ? PAYMENT_METHODS.CREDIT_CARD
            : PAYMENT_METHODS.DEBIT_CARD
        updateImageFlags(selectedCardType.code)
      }
    }

    function updateImageFlags(selectedCardType) {
      resetCardsImagesFlags()
      const item = cardHelper.findTypeByProperty('code', selectedCardType)

      switch (item?.name) {
        case CARD_FULL_TYPE.VISA_DEBIT:
        case CARD_FULL_TYPE.VISA_CREDIT:
          isVisaCard.value = true
          showCardsImages.value = false
          break
        case CARD_FULL_TYPE.MASTERCARD_CREDIT:
        case CARD_FULL_TYPE.MASTERCARD_DEBIT:
          isMastercard.value = true
          showCardsImages.value = false
          break
        default:
          showCardsImages.value = true
          break
      }
    }

    const prefixYear = (value) => {
      if (value.length < 5) {
        return
      }
      let month = value.substring(0, 2)
      let year = value.substring(3, 5)
      return `${month}/20${year}`
    }

    const inFuture = {
      $validator(v, context) {
        const dateNow = Date.now()

        const lastDayDatePrevMonth = subMonths(endOfMonth(dateNow), 1)

        const expiredDate = parse(prefixYear(v), 'MM/yyyy', new Date())

        if (!context['expirationDate'].validDate.$invalid) {
          return isValid(expiredDate) ? isAfter(expiredDate, lastDayDatePrevMonth) : true
        } else {
          return true
        }
      },
      $message: $t('ComponentAddPaymentCardModal.ValidationFutureDate').value,
    }

    const validDate = {
      $validator(v) {
        const expiredDate = parse(prefixYear(v), 'MM/yyyy', new Date())
        const maxDate = addYears(Date.now(), 10)

        return isValid(expiredDate) ? isBefore(expiredDate, maxDate) : false
      },
      $message: $t('ComponentAddPaymentCardModal.ValidationValidDate').value,
    }

    const cvvMinLength = helpers.regex(/^.{3,3}$/i)
    const cardNumberMinLength = ref(0)

    const showCardHolderNameMinWordsError = ref(false)

    const showCardNumberNotSupported = ref()
    const isValidNumberError = ref(
      $t('ComponentAddPaymentCardModal.ValidationCardNumberValid').value
    )

    const firstNameRegex = new RegExp(/^[\u0020*+a-zA-Z\-\.\u0027]{1,50}$/)
    const lastNameRegex = new RegExp(/^[a-zA-Z\-\u0027\u0020*]{1,50}$/)

    // const validation = ref(createValidation())
    const validation = useValidation({
      cardNumber: {
        $value: toRef(form, 'cardNumber'),
        minLength: {
          $validator(value) {
            if (!showCardNumberNotSupported.value) {
              return minLength(cardNumberMinLength).$validator(value)
            }
            return true
          },
          $message: $t('ComponentAddPaymentCardModal.ValidationCardNumberValid').value,
        },
        isValidNumber: {
          $validator() {
            return !showCardNumberNotSupported.value
          },
          $message: isValidNumberError, //$t('ComponentAddPaymentCardModal.ValidationCardNumberValid').value,
        },
      },
      expirationDate: {
        $value: toRef(form, 'expirationDate'),
        validDate,
        inFuture,
      },
      cardHolderName: {
        $value: toRef(form, 'cardholderName'),
        valueRequired,
        // TODO needs to be checked - removed since it seems we can create accounts like Roberto's account where the name is "Mr Roberto Iglesias" and then it would throw an error
        containsAppellatives: {
          $validator(v) {
            return !appellativeWordsRegex.test(v)
          },
          $message: $t('CardPayment.AppellativesErrorText').value, //'First and last name only - don’t include Mr, Ms, Mrs, Miss.',
        },
        isFirstNameAndLastNameValid: {
          $validator(v, context) {
            if (
              context['cardHolderName'].valueRequired.$invalid ||
              context['cardHolderName'].containsAppellatives.$invalid
            ) {
              return true
            }
            const firstName = form.getFirstName()
            const lastName = form.getSurname()

            return (
              firstNameRegex.test(firstName) && (lastName ? lastNameRegex.test(lastName) : true)
            )
          },
          $message: $t('CardPayment.CheckIfNameMatchesCardText').value,
        },
        minWords: {
          $validator(v, context) {
            return !(
              showCardHolderNameMinWordsError.value &&
              !context['cardHolderName'].valueRequired.$invalid &&
              !context['cardHolderName'].isFirstNameAndLastNameValid.$invalid
            )
          },
          $message: $t('CardPayment.FirstAndLastNameRequiredErrorText').value, //'First name and last name are required'
        },
      },
      securityCode: {
        $value: toRef(form, 'securityCode'),
        valueRequired,
        numeric,
        minLength: {
          $validator: cvvMinLength,
          $message: $t('CardPayment.InvalidCVVErrorText').value,
        },
      },
      cardTypeId: {
        $value: toRef(form, 'cardTypeId'),
        valueRequired,
      },
    })

    const resetCardsImagesFlags = () => {
      isVisaCard.value = false
      isMastercard.value = false
      showCardsImages.value = true
    }

    watch(
      () => form.cardNumber,
      async (newCardNumber, oldCardNumber) => {
        resetCardsImagesFlags()

        let result = await validatedCardInformation(newCardNumber, oldCardNumber)

        if (result?.cardBrand) {
          updateImageFlags(result.cardBrand)
        }

        //result null means we dont trigger validation so nothing should change
        if (result && (result.error || result.cardType || result.shouldShowCardSelection)) {
          if (result.shouldShowCardSelection) {
            showCardType.value = true
          } else if (result.error) {
            isValidNumberError.value = result.error
            showCardNumberNotSupported.value = true
            // validation.cardNumber.$touch()
          } else {
            showCardType.value = false
            showCardNumberNotSupported.value = false

            form.cardBrand = result.cardBrand
            form.cardPspCode = result.cardPspCode
            form.cardTypeId = result.cardId
            form.cardType = result.cardType
          }
        }
      }
    )

    watch(
      () => form.expirationDate,
      (newValue, oldValue) => {
        if (!oldValue) {
          oldValue = 'MM/YY'
        }
        if (oldValue && newValue !== oldValue) {
          if (newValue.length > oldValue.length) {
            form.expirationDate = newValue
              .replace(/(^[0-9]{2})$/g, '$1/')
              .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
              .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
              .replace(/^1([3-9])$/g, '01/$1')
              .replace(/^(0\/|0+)+$/g, '0')
              .replace(/[^\d|^\/]*/g, '')
              .replace(/\/\//g, '/')

            form.expirationDate = form.expirationDate.substr(0, 5)
          }

          //check if it contains the slash
          if (!form.expirationDate.includes('/') && form.expirationDate.length === 4) {
            let firstDigits = form.expirationDate.slice(0, 2)
            let lastDigits = form.expirationDate.slice(2)
            form.expirationDate = firstDigits + '/' + lastDigits
          }
        }
      }
    )

    const {
      exec: addCard,
      error: addCardError,
      loading: addCardLoading,
      result: addCardResults,
    } = usePromiseLazy((adyenDataObj) => {
      sanitizeBillingAddressFields(billingAddress.value)

      if (adyenDataObj) {
        customCard.submit()

        if (!adyenDataObj.cardType) {
          const cardType = cardHelper.findTypeByProperty('id', form.cardTypeId)
          form.cardType = cardType.cardType
          form.cardBrand = cardType.code
        } else {
          const cardFullName = cardHelper.getCardFullName(
            adyenDataObj,
            adyenDataObj.cardType ?? form.cardType
          )

          const selectedCardType = cardHelper.findTypeByProperty('name', cardFullName)

          form.cardType = selectedCardType.cardType
          form.cardBrand = selectedCardType.code
          form.cardTypeId = selectedCardType.id
        }
      }

      let cardData = {
        billingAddress: {
          firstName: form.getFirstName(),
          lastName: form.getSurname(),
          country: billingAddress.value.country,
          street: getCorrectedString(billingAddress.value.street),
          city: getCorrectedString(billingAddress.value.city),
          state: getCorrectedString(billingAddress.value.state),
          postalCode: getCorrectedString(billingAddress.value.postalCode),
        },
        postalCode: getCorrectedString(billingAddress.value.postalCode),
        securityCode: form.securityCode,
        country: billingAddress.value.country,
        cardType: form.cardTypeId, //.toString().padStart(3, 0),
        cardPspCode: form.cardPspCode,
        saveCard: form.saveCard,
      }

      const legacyGatewayData = {
        cardNumber: form.cardNumber,
        expiryMonth: form.expirationDate.substr(0, 2),
        expiryYear: '20' + form.expirationDate.substr(3, 2),
      }

      if (adyenDataObj) {
        cardData.adyenData = adyenDataObj
      } else {
        cardData = {
          ...cardData,
          ...legacyGatewayData,
        }
      }

      return cardAccount.addCard(cardData)
    })

    watch(adyenData.value, (newValue) => {
      if (
        !newValue.cardType &&
        newValue.isValid &&
        !newValue.validationError &&
        !newValue.loading
      ) {
        showCardType.value = true
        form.cardType = null
        form.cardTypeId = null
      } else {
        showCardType.value = false
      }
    })

    const submit = async () => {
      await cardAccount.fetchCardSettings(billingAddress.value.country)

      let param = null
      if (isAdyen.value) {
        param = adyenData.value
      }
      await addCard(param)

      if (addCardResults.value) {
        let analyticsCardType, message

        if (form.cardType === PAYMENT_METHODS.DEBIT_CARD) {
          analyticsCardType = SEGMENT_PAYMENT_METHOD_TYPES.DEBIT
          message = $t('ComponentAddPaymentCardModal.AlertDebitCardAdded').value
        } else {
          analyticsCardType = SEGMENT_PAYMENT_METHOD_TYPES.CREDIT
          message = $t('ComponentAddPaymentCardModal.AlertCreditCardAdded').value
        }

        if (router.currentRoute.meta.continueSendFlow) {
          analyticsStore.track({
            event: SEGMENT_EVENTS.PAYMENT_METHOD_SELECTED,
            traits: {
              paymentType: analyticsCardType,
              cardBrand: CARD_BRAND_MAPPINGS[form.cardBrand],
              cardBillingCountry: billingAddress.value.country,
              paymentProvider: paymentGateway,
              newCard: isNewCard,
              location: SEGMENT_LOCATIONS.SEND_MONEY,
            },
          })

          sendMoneyStore.form.cardSecurityCode = form.securityCode

          sendMoneyStore.setPaymentMethodName({
            paymentMethodTitle: cardHelper.createCardTitle(addCardResults.value, form.cardBrand),
            paymentMethodCaption: '',
            paymentMethodText: cardHelper.findTypeByProperty('code', form.cardBrand).name,
            paymentMethodNumber: addCardResults.value.maskedAccountNumber,
          })

          const paymentMethods = sendMoneyStore.form.paymentMethods

          const selectedPaymentMethod = paymentMethods.find(
            (paymentMethod) => paymentMethod.value === form.cardType
          )

          sendMoneyStore.setPaymentMethodAction(selectedPaymentMethod.value)
          sendMoneyStore.setPaymentMethodType(selectedPaymentMethod.value)
          sendMoneyStore.form.paymentMethodId = addCardResults.value.id
          router.replace('/send-money/summary')
        } else {
          router.toParentRoute()
        }

        addAlert(message)

        analyticsStore.track({
          event: SEGMENT_EVENTS.NEW_PAYMENT_METHOD_ADDED,
          traits: {
            paymentType: analyticsCardType,
            cardBrand: CARD_BRAND_MAPPINGS[form.cardBrand],
            isSavedEnabled: form.saveCard,
            isBillingAddressSameAsResidentalAddress: sameAsResidentialAddressFlag.value,
            cardBillingCountry: newCard.billingCountry,
            paymentProvider: paymentGateway,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
          },
        })
        cardAccount.cardBillingCountry = billingAddress.value.country
        cardAccount.cardBrand = CARD_BRAND_MAPPINGS[newCard.type]
      }

      if (addCardError.value) {
        const errorMessage = addCardError.value.friendlyMessage
          ? addCardError.value.friendlyMessage
          : $t('ComponentAddPaymentCardModal.ErrorAdd').value
        const error = {
          title: $t('ComponentAddPaymentCardModal.ErrorAddTitle').value,
          text: errorMessage,
        }

        appStore.logException(
          'Exception during adding card',
          errorMessage ?? 'Exception during adding card'
        )
        appStore.messageBoxGenericOk(error.title, error.text)
      }
    }

    const resetMinLength = () => {
      if (cardNumberMinLength.value === 16) {
        cardNumberMinLength.value = 0
      }
    }

    const onCardNumberFocusOut = () => {
      cardNumberMinLength.value = 16
      validation.cardNumber.minLength.$touch()
    }

    const resetCardHolderNameMinWords = () => {
      showCardHolderNameMinWordsError.value = false
    }

    const onCardHolderNameFocusOut = () => {
      const cardHolderNameWordsLength = form.cardholderName.trim().split(' ').length
      if (cardHolderNameWordsLength < 2) {
        showCardHolderNameMinWordsError.value = true
      }
    }

    const isContinueDisabled = computed(() => {
      // Group related conditions and use descriptive variable names
      const isAdyenPayment = isAdyen.value
      const isBillingInvalid = !isBillingAddressValid.value

      if (isAdyenPayment) {
        const adyenValidationFailed = adyenData.value.validationError !== null
        const adyenIsValid = adyenData.value.isValid
        const isCardTypeMissing = form.cardTypeId === null && showCardType.value

        const hasAdyenErrors =
          !adyenIsValid || (adyenValidationFailed && adyenIsValid) || isCardTypeMissing

        // Combine all Adyen related checks
        return (
          hasAdyenErrors ||
          validation.cardHolderName.$anyInvalid ||
          isBillingInvalid ||
          adyenData.value.loading
        )
      }

      // Non-Adyen payment checks
      const hasFormErrors = validation.$anyInvalid
      const isCardNotSupported = showCardNumberNotSupported.value

      return hasFormErrors || isCardNotSupported || isBillingInvalid
    })

    return {
      form,
      newCard,
      billingAddress,
      submit,
      country,
      clickSecurityInfo,
      isCVVModalOpen,
      addCardLoading,
      model,
      validation,
      cardName,
      newCardName,
      showCardType,
      onCardTypeChange,
      billingAddressDetailsFields: computed(() => billingAddressDetails.value),
      $t,
      showCardNumberNotSupported,
      cardHelper,
      resetMinLength,
      onCardNumberFocusOut,
      resetCardHolderNameMinWords,
      onCardHolderNameFocusOut,
      isAdyen,
      adyenData,
      isContinueDisabled,
      isVisaCard,
      showCardsImages,
      isMastercard,
      cardIconSrc,
      pageTitle,
      pageCreditHeader,
      pageDebitHeader,
      checkboxState,
      handleCheckboxStateChange,
      isBillingAddressValid,
      onBillingAddressInfoSaved,
      isNewCard,
      sameAsResidentialAddressFlag,
    }
  },
}
</script>

<style scoped>
@import 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/6.0.0/adyen.css';

.card-info {
  @apply mb-2 text-secondary-text;

  .card-type {
    @apply capitalize;
  }
}

.details-p {
  @apply text-secondary-text text-center text-base mb-8;
}

.ba-title {
  @apply text-primary-text text-lg font-medium;
}

.address-short {
  @apply text-xs;
}

::v-deep .list-item-title {
  @apply font-medium text-base;
}

::v-deep .list-item-caption {
  @apply text-xs font-normal;
}
::v-deep .list-item-button {
  @screen sm {
    &:hover,
    &:focus {
      @apply rounded-md;
      box-shadow: -16px 0 0 var(--theme-list-item-hover-color, theme('colors.gray.hover')),
        16px 0 0 var(--theme-list-item-hover-color, theme('colors.gray.hover'));
      background: var(--theme-list-item-hover-color, theme('colors.gray.hover'));
    }
  }
}

::v-deep .figure {
  @apply shadow-ria-2;
  background-color: white;
}

.xe-figure {
  @apply font-semibold text-sm;
}

.card-images {
  display: flex;
  justify-content: right;
  width: 60px;

  img {
    @apply inline-block;
    @apply mr-2;
  }
}

::v-deep .card-footer > .button {
  @apply w-full !important;
}

::v-deep .card-footer > .button:disabled {
  @apply bg-blue-button-disabled text-white !important;
}

.checkbox-container {
  @apply mt-6 mb-6 p-0 shadow-ria-1;
  border-radius: 16px !important;
  border: 0.5px solid #dddddd;
}
::v-deep .checkbox-container .card-content {
  @apply m-0 p-2;
}
::v-deep .checkbox-container .card-content-block {
  @apply m-0;
}

/* Adyen styles start */

::v-deep #customCard-container {
  height: 0px;
  &.card-fields-loaded {
    height: auto;
  }
}

::v-deep .adyen-checkout-form-instruction {
  display: none;
}

::v-deep .adyen-checkout__input-wrapper {
  height: 48px;
  border-radius: 6px;
  border-color: theme('colors.gray.light');
  box-shadow: theme('colors.gray.lighter') 0px 3px 15px 0px;
}

::v-deep .adyen-checkout-contextual-text--error {
  font-weight: 500;
}

::v-deep .adyen-checkout__label__text--error {
  color: #333;
}

.adyen-validation-error {
  @apply mt-6;
  color: #cd3d64;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

::v-deep
  .adyen-checkout__field--error
  .adyen-checkout__label--focused
  + .adyen-checkout__input-wrapper {
  border-color: rgba(237, 95, 116, 1);
  box-shadow: inset 0 0 0 1px rgba(237, 95, 116, 1);
}

::v-deep .adyen-checkout__spinner {
  display: none !important;
}

::v-deep .adyen-checkout__spinner:after {
  border-top-color: var(--theme-color-primary, rgba(0, 108, 224, 1));
}
/* Adyen styles end */

::v-deep .js-iframe-input {
  height: 48px !important;
}

.checkbox-container .checkbox-row {
  @apply flex justify-start items-center text-secondary-text text-sm;
}

.card-warning {
  @apply py-2 px-3 mt-6 mb-6 leading-5 text-sm rounded font-normal;
  color: theme('colors.blue.default');
}

.not-supported {
  color: rgba(237, 95, 116, 1);
  margin-top: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.015625rem;
  line-height: 1rem;
}

.loading-container {
  @apply flex items-center justify-center;
  min-height: 240px;
  ::v-deep .loading-spinner-container {
    @apply relative;
  }
}

::v-deep .adyen-checkout__card__brands__brand-wrapper {
  height: var(--adyen-sdk-spacer-070, 20px);
  width: auto;
  margin: 0;
}

.card-details-title {
  @apply text-left mb-4 leading-8 font-medium text-2xl;
}

.card-details-info {
  @apply text-left leading-6 font-light text-base h-12 w-80 text-tertiary-text;
}

.checkmark-icon {
  color: #07893c !important;
}

.cards-images {
  @apply mb-5 flex items-center;
}

.cards-images img {
  @apply w-7 h-5;
  margin-right: 5px;
}

.margin-update {
  @apply mb-2;
}

.add-margin {
  @apply mt-6;
}

::v-deep .input-label {
  @apply font-medium text-base leading-6 !important;
  color: #333333 !important;
}

::v-deep .adyen-checkout__label__text {
  @apply font-medium text-base leading-6 !important;
  color: #333333 !important;
}
</style>

import { reactive, ref } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'
import useCardHelper from '@galileo/composables/useCardHelper/'

import { useCardAccountStore, useEnvStore } from '@galileo/stores'

export default function () {
  const { retrieveCardSettings, cardTypeList } = useCardHelper()

  const checkCardInfoRequest = reactive(
    usePromiseLazy(async (binNumber) => await useCardAccountStore().requestCardInfo(binNumber))
  )

  const CARDS_NUMBER_DIGITS_TO_VALIDATE = 8

  const validatedCardInformation = async function (cardNumber, oldCardNumber) {
    await retrieveCardSettings()

    const envStore = useEnvStore()
    let form = reactive({
      cardBrand: '',
      cardType: null,
      cardPspCode: null,
      shouldShowCardSelection: false,
      error: null,
    })
    if (
      cardNumber.length >= CARDS_NUMBER_DIGITS_TO_VALIDATE &&
      (!oldCardNumber ||
        cardNumber.slice(0, CARDS_NUMBER_DIGITS_TO_VALIDATE) !==
          oldCardNumber.slice(0, CARDS_NUMBER_DIGITS_TO_VALIDATE))
    ) {
      try {
        const bin = cardNumber.slice(0, CARDS_NUMBER_DIGITS_TO_VALIDATE)

        if (envStore.env.VUE_APP_IS_TESTSYSTEM) {
          /*
                      "cardTypes": [{
                    "id": 1,
                    "name": "Visa Debit",
                    "paymentType": "DebitCard",
                    "code": "DELTA"
                }, {
                    "id": 2,
                    "name": "Visa Kredit",
                    "paymentType": "CreditCard",
                    "code": "VISA"
                }, {
                    "id": 3,
                    "name": "Mastercard Debit",
                    "paymentType": "DebitCard",
                    "code": "MCD"
                }, {
                    "id": 4,
                    "name": "Mastercard Kredit",
                    "paymentType": "CreditCard",
                    "code": "MCC"
                }
            ],
          */

          //NOTE
          //since implemented the dropdown selection this is not needed anymore

          // if (bin === '445653' || bin === '400000' || bin === '444400') {
          //   // VISA test cards
          //   form.cardBrand = 'VISA'
          //   form.cardType = 'CreditCard'
          //   form.cardPspCode = '001'
          //   form.shouldShowCardSelection = false
          //   form.cardId = 1
          //   return form
          // } else

          //Mastercards are from malaysia so bin lookup will return an error while trying them
          if (bin === '520000' || bin === '544400') {
            // Mastercard test cards
            form.cardBrand = 'MCC'
            form.cardType = 'CreditCard'
            form.cardPspCode = '002'
            form.shouldShowCardSelection = false
            form.cardId = 3
            return form
          }
        }

        const response = await checkCardInfoRequest.exec(bin)

        let type = cardTypeList.value.find((type) => type.name === response?.data?.name)

        if (type && response?.data) {
          let data = response.data

          form.cardBrand = data.cardBrand || ''
          form.cardType = data.cardType
          form.cardPspCode = type?.cardPspCode || data.cardPspCode
          form.shouldShowCardSelection = false
          form.cardId = data.id
        } else if (response.error || !type) {
          form.error = response.error
          if (response.status == 404 || (!type && response.status !== 400)) {
            form.shouldShowCardSelection = true
          }
        }
        return form
      } catch (e) {
        form.error = e
        return form
      }
    }
    if (cardNumber.length < 6) return form
    return null
  }

  return {
    validatedCardInformation,
    loading: checkCardInfoRequest.loading,
    cardTypeList,
    retrieveCardSettings,
  }
}

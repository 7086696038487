













































import { ref, computed, onBeforeMount, watch, defineComponent } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

import Address from '@galileo/models/Address/address'
import AddressModel from '@galileo/models/Address/BillingAddresses/AddressModel'
import XeAppDescriptionList from '@galileo/components/XeAppDescriptionList/XeAppDescriptionList.vue'

import GalileoAddressPicker from './BillingAddressCases/GalileoAddressPicker.vue'

import {
  useVModel,
  AppCard,
  AppCardHeader,
  AppCardFooter,
  AppDescriptionListItem,
  AppInputCountry,
  AppButton,
  AppInputCheckbox,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useAuthStore,
  useCountriesStore,
  usePaymentsStore,
  useAnalyticsStore,
  useSessionStore,
} from '@galileo/stores'

import { PaymentAccount, CardAccount } from '@galileo/models/payments/interfaces/paymentsTypes'

export default defineComponent({
  components: {
    AppCard,
    AppCardHeader,
    AppCardFooter,
    XeAppDescriptionList,
    AppDescriptionListItem,
    AppButton,
    AppInputCheckbox,
    AppInputCountry,
    GalileoAddressPicker,
  },
  // emit: ['click', 'edit', 'onSave'],
  props: {
    fields: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    ctaLabel: {
      type: String,
      default: '',
    },
    ctaLink: {
      type: String,
      default: '',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    isNewCard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props: any, { emit }: any) {
    const { model } = useVModel(props, emit)
    const router = useRouter()

    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const countriesStore = useCountriesStore()
    const paymentsStore = usePaymentsStore()
    const analyticsStore = useAnalyticsStore()
    const sessionStore = useSessionStore()

    const loading = ref<boolean>(false)
    //No need for now
    const countries = ref()
    const cardSetting = ref({ cardBillingCountries: [] })
    const filteredCountries = ref([])

    const isInputVisible = ref(true)
    if (router.app._route.path === '/payment-methods/card-details') {
      isInputVisible.value = false
    }

    const paymentAccount = computed(
      (): PaymentAccount => paymentsStore.selectedPaymentMethod as PaymentAccount
    )

    const countriesList = countriesStore.getCountries
    const sameAsResidentialAddress = ref<boolean>(false)
    const newCard = paymentsStore.selectedPaymentMethod
    const selectedCardAddress = ref<Address>(new Address())
    const billingAddress = ref<any>({})
    const isBillingAddressValid = ref(false)
    const userAddressModel = ref(new AddressModel())
    const inModal = ref(false)

    const cardAccount = paymentAccount.value as CardAccount

    // we fill in the billing address information in here
    onBeforeMount(async () => {
      loading.value = true

      if (!paymentAccount.value.country) {
        const user = authStore.userProfile
        paymentAccount.value.country = user.country
      }

      mapBillingAddressFromSelectedCard()

      clearBillingAddress()

      loading.value = false
    })

    const mapBillingAddressFromSelectedCard = () => {
      selectedCardAddress.value = new Address(
        newCard.billingStreet,
        newCard.billingCity,
        newCard.billingState,
        newCard.billingPostalCode,
        newCard.billingCountry
      )
    }

    const clearBillingAddress = (country: Nullable<string> = null) => {
      if (!country) {
        const user = authStore.userProfile
        country = paymentAccount.value.billingCountry ?? user.country
      } else {
        userAddressModel.value = new AddressModel()
        userAddressModel.value.countryCode = country
        submit(false)
        return
      }

      userAddressModel.value = new AddressModel('', '', '', '', '', '', country)
      billingAddress.value = new Address('', '', '', '', country)
      isBillingAddressValid.value = false

      submit(false)
    }

    const sanitizeAddressFields = (addressString: string) => {
      if (addressString) {
        return removeDiacritics(translit(addressString))
      }
      return ''
    }

    const mapToBillingAddress = () => {
      const newAddressLine1 = sanitizeAddressFields(userAddressModel.value.line1)
      const newAddressLine2 = sanitizeAddressFields(userAddressModel.value.line2)
      const newAddressLine3 = sanitizeAddressFields(userAddressModel.value.line3)
      const newAddressCountry = sanitizeAddressFields(userAddressModel.value.countryCode)
      const newAddressState = sanitizeAddressFields(userAddressModel.value.state)
      const newAddressCity = sanitizeAddressFields(userAddressModel.value.place)
      const newAddressPostalCode = sanitizeAddressFields(userAddressModel.value.postCode)

      billingAddress.value = {
        cardholderName: `${cardAccount.firstName} ${cardAccount.lastName}`,
        billingAddress: newAddressLine1,
        billingAddress2: newAddressLine2,
        billingAddress3: newAddressLine3,
        billingCountry: newAddressCountry,
        billingCity: newAddressCity,
        billingPostalCode: newAddressPostalCode,
        billingState: newAddressState,
      }
    }

    watch(sameAsResidentialAddress, async (newVal: boolean) => {
      emit('updateCheckboxState', newVal)

      if (newVal) {
        userAddressModel.value = useAuthStore().getUserAddress()

        analyticsStore.track({
          event: SEGMENT_EVENTS.CARD_BILLING_ADDRESS_SAME_AS_RESIDENTIAL,
          traits: {
            paymentType: paymentAccount.value.cardType,
            cardBillingCountry: paymentAccount.value.country,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            brand: sessionStore.brand,
          },
        })

        isBillingAddressValid.value = true
        submit(true)
      } else {
        analyticsStore.track({
          event: SEGMENT_EVENTS.CARD_BILLING_ADDRESS_NOT_SAME_AS_RESIDENTIAL,
          traits: {
            paymentType: paymentAccount.value.cardType,
            cardBillingCountry: paymentAccount.value.country,
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            brand: sessionStore.brand,
          },
        })
        clearBillingAddress()
      }
    })

    const submit = async (addressValid: boolean) => {
      mapToBillingAddress()

      emit('onSave', {
        address: billingAddress.value,
        addressValid,
      })
    }

    function getCountry(country: string) {
      let result = countriesList.find((cty: any) => cty.value === country)

      if (result) {
        return result?.text
      }
    }

    const generateAddressString = computed((): string => {
      const address = selectedCardAddress.value

      const lines = [
        address.street, // Street
        [address.city, address.state, address.postalCode].filter(Boolean).join(', '),
        address.country ? getCountry(address.country) : null,
      ]

      // Filter out empty or null lines and wrap in HTML
      return lines
        .filter(Boolean) // Remove null/undefined/empty strings
        .map((line) => `<span>${line}</span><br/>`) // Wrap each line in <span> and <br/>
        .join('')
    })

    // Segment event that triggers when user starts typing the address
    const onSelectAddress = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.EDIT_CARD_BILLING_ADDRESS_SELECTED,
        traits: {
          paymentType: paymentAccount.value.cardType,
          cardBillingCountry: paymentAccount.value.country,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
          brand: sessionStore.brand,
        },
      })
    }

    // Segment event that triggers when user starts typing the address
    const onManualAddressSelect = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.EDIT_CARD_BILLING_ADDRESS_MANUALLY,
        traits: {
          paymentType: paymentAccount.value.cardType,
          cardBillingCountry: paymentAccount.value.country,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
          brand: sessionStore.brand,
        },
      })
    }

    return {
      userAddressModel,
      model,
      billingAddress,
      sameAsResidentialAddress,
      submit,
      loading,
      $t,
      countries,
      cardSetting,
      filteredCountries,
      isBillingAddressValid,
      isInputVisible,
      generateAddressString,
      mapBillingAddressFromSelectedCard,
      selectedCardAddress,
      inModal,
      clearBillingAddress,
      onSelectAddress,
      onManualAddressSelect,
    }
  },
})

<template>
  <div v-if="isLoading" class="loader-wrapper">
    <div class="flex loader-content flex-col">
      <div class="loading-field-wrapper">
        <div class="label"></div>
        <div class="input"></div>
        <div class="full-width no-background card-wrapper">
          <div class="card"></div>
          <div class="card"></div>
        </div>
      </div>
      <div class="flex flex-row items-center">
        <div class="loading-field-wrapper half-width pr-2">
          <div class="label"></div>
          <div class="input"></div>
          <div class="label"></div>
        </div>
        <div class="loading-field-wrapper half-width pl-2">
          <div class="label"></div>
          <div class="input"></div>
          <div class="label"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardFieldsLoader',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  setup() {
    return {}
  },
}
</script>

<style scoped>
.loader-wrapper {
  height: 220px;
  .loader-content {
    .loading-field-wrapper div {
      @apply flex py-2;
      &:not(.no-background) {
        background-color: #e0e0e0;
        background-image: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
        background-size: 200% 100%;
        animation: shimmer 1.5s infinite linear;
        border-radius: 4px;
        margin-bottom: 6px;
      }

      &.card-wrapper {
        @apply mb-1 pt-0;
        display: inline-flex !important;
        .card {
          @apply py-0;
          margin-right: 3px;
          border-radius: 2px !important;
          height: 16px;
          width: 24px;
        }
      }

      &.label {
        @apply py-1;
        height: 6px;
        width: 120px;
      }
      &.input {
        height: 40px;
        width: 100%;
      }
    }

    .half-width {
      width: 50%;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
</style>
